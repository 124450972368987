<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="filterItems"
            class="elevation-0"
            :mobile-breakpoint="0"
            :loading="loading"
            disable-pagination
            hide-default-footer
        >
            <template v-slot:top>
                <v-row
                    no-gutter
                    class="mt-0 mx-0 pt-4 pb-0"
                    style="background-color: #eeeeee"
                >
                    <div class="d-flex">
                        <template>
                            <v-card
                                class="pt-3 pl-2 pr-5 mx-3"
                                min-width="180px"
                                height="75px"
                            >
                                <v-card-text
                                    class="d-flex my-0 py-0 mx-0 px-0"
                                    width="100%"
                                >
                                    <v-chip
                                        class="mb-1 mt-0 mx-2 pt-0 white--text"
                                        :color="insights[0].color"
                                        label
                                        style="height: 50px"
                                    >
                                        <v-icon center>
                                            {{ insights[0].icon }}
                                        </v-icon>
                                    </v-chip>
                                    <div class="mb-1 mt-0 pt-0">
                                        <div
                                            class="d-flex mr-0 pr-0 text-capitalize"
                                        >
                                            <span
                                                style="font-size: 14px; font-weight: bold;"
                                                >{{ insights[0].title }}</span
                                            >
                                        </div>
                                        <p class="text-h6 black--text">
                                            $ {{ calculateTotal() }}
                                        </p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </template>
                    </div>
                    <v-col cols="12" class="d-flex">
                        <v-combobox
                            :items="codes"
                            rounded
                            outlined
                            hide-details
                            dense
                            prepend-inner-icon="mdi-magnify"
                            append-icon=""
                            ref="numberComboBox"
                            v-model="search"
                            placeholder="Search"
                            item-text="description"
                            :search-input.sync="searchText"
                            @change="searchItem"
                        >
                            <!-- Slot para personalizar los elementos de la lista -->
                            <template v-slot:item="{ item, props }">
                                <div
                                    v-bind="props"
                                    class="d-flex align-center"
                                    style="width: 100%;"
                                >
                                    <span
                                        class="text-truncate"
                                        style="font-size: 14px;"
                                        >{{ item.description }}</span
                                    >

                                    <v-btn
                                        icon
                                        small
                                        class="ml-auto"
                                        @click.stop="handleButtonClick(item)"
                                    >
                                        <v-icon>mdi-qrcode-scan</v-icon>
                                    </v-btn>
                                </div>
                            </template>
                            <!-- Botón de reinicio en el slot de append -->
                            <template v-slot:append>
                                <v-btn
                                    v-if="reset"
                                    icon
                                    color="red"
                                    small
                                    @click="resetSearch"
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-combobox>
                        <v-spacer />
                        <v-btn
                            rounded
                            color="primary"
                            @click="openDialogNewItem"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-plus
                            </v-icon>
                            {{ $vuetify.breakpoint.smAndDown ? '' : 'NEW' }}
                        </v-btn>
                        <v-btn
                            v-if="uploadStock"
                            rounded
                            color="primary"
                            @click="openDialogToUploadFile"
                            class="mx-2"
                        >
                            <v-icon>
                                mdi-arrow-up
                            </v-icon>
                            UPLOAD
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
            <!--ITEMS-->
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn small icon @click.stop="openDialogReserve(item)">
                        <v-icon>mdi-database-edit-outline</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <!--New Item-->
        <v-dialog
            :retain-focus="false"
            v-model="openNewItem"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <NewItem
                v-if="openNewItem"
                @closeDialog="closeDialogNewItem"
                @save="save"
            />
        </v-dialog>
        <!-- Modal -->
        <v-dialog
            v-model="dialog"
            :retain-focus="false"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <v-card>
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="dialog = false"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>Stock Material Details</h3>
                </v-card-title>
                <v-divider class="ml-4 mr-5" />
                <v-card-text>
                    <v-form ref="form">
                        <v-container>
                            <v-row>
                                <v-col cols="12" class="px-0">
                                    <v-text-field
                                        label="NAME"
                                        v-model="formData.name"
                                        disabled
                                    ></v-text-field>
                                </v-col>

                                <v-row>
                                    <v-col
                                        :cols="
                                            $vuetify.breakpoint.mobile ? 12 : 6
                                        "
                                    >
                                        <v-text-field
                                            label="ESM CODE"
                                            v-model="formData.cod_esm"
                                            disabled
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        :cols="
                                            $vuetify.breakpoint.mobile ? 12 : 6
                                        "
                                    >
                                        <v-text-field
                                            label="TGLS CODE"
                                            v-model="formData.cod_tgls"
                                            :maxlength="11"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        :cols="
                                            $vuetify.breakpoint.mobile ? 12 : 4
                                        "
                                    >
                                        <v-text-field
                                            label="AA"
                                            v-model="formData.aa"
                                            :maxlength="6"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        :cols="
                                            $vuetify.breakpoint.mobile ? 12 : 4
                                        "
                                    >
                                        <v-text-field
                                            label="TPLE"
                                            v-model="formData.tple"
                                            :maxlength="4"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        :cols="
                                            $vuetify.breakpoint.mobile ? 12 : 4
                                        "
                                    >
                                        <v-combobox
                                            v-model="formData.uc"
                                            :items="
                                                codesUCList.map(
                                                    option => option.name
                                                )
                                            "
                                            label="UC CODE"
                                            item-text="name"
                                            item-value="name"
                                            clearable
                                        ></v-combobox>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        :cols="
                                            $vuetify.breakpoint.mobile ? 12 : 6
                                        "
                                    >
                                        <v-text-field
                                            label="ACB"
                                            v-model="formData.acb"
                                            :maxlength="15"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        :cols="
                                            $vuetify.breakpoint.mobile ? 12 : 6
                                        "
                                    >
                                        <v-text-field
                                            label="CLR"
                                            v-model="formData.clr"
                                            :maxlength="17"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        :cols="
                                            $vuetify.breakpoint.mobile ? 12 : 6
                                        "
                                    >
                                        <v-text-field
                                            label="OC"
                                            v-model="formData.oc"
                                            :maxlength="17"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col
                                        :cols="
                                            $vuetify.breakpoint.mobile ? 12 : 6
                                        "
                                    >
                                        <v-combobox
                                            v-model="formData.proy"
                                            :items="
                                                projects.map(
                                                    project =>
                                                        `${project.reference} - ${project.name}`
                                                )
                                            "
                                            label="PROJECT"
                                            item-text="name"
                                            item-value="reference"
                                            clearable
                                        ></v-combobox>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="saveForm"
                        rounded
                        color="primary"
                        class="mx-2"
                        :disabled="!validateForm"
                    >
                        <v-icon>
                            mdi-file-document-plus-outline
                        </v-icon>
                        Generate PDF
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Item Reserve-->
        <v-dialog
            :retain-focus="false"
            v-model="openReserve"
            persistent
            fullscreen
        >
            <ItemReserve
                v-if="openReserve"
                :item="selectedItem"
                :projectId="projectId"
                @closeDialog="closeDialogReserve"
                @reserve="reserve"
            />
        </v-dialog>
        <!--Upload File-->
        <v-dialog
            :retain-focus="false"
            v-model="openUploadFile"
            persistent
            :width="$vuetify.breakpoint.mobile ? '80%' : '40%'"
        >
            <v-card :loading="loading2" v-if="openUploadFile">
                <v-card-title>
                    <v-btn
                        small
                        icon
                        @click="closeDialogToUploadFile"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>Upload File</h3>
                </v-card-title>
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="mt-4">
                    <v-form v-model="valid">
                        <v-row>
                            <v-col cols="12">
                                <v-file-input
                                    v-model="file"
                                    label="Stock"
                                    hide-details
                                    accept=".xlsx"
                                >
                                </v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12">
                                <v-file-input
                                    v-model="codesFile"
                                    label="Codes"
                                    hide-details
                                    accept=".xlsx"
                                >
                                </v-file-input>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        class="mr-2 mb-3"
                        rounded
                        :disabled="!file && !codesFile"
                        @click="uploadFile"
                        :loading="loading2"
                    >
                        UPLOAD
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-alert
            v-if="activateAlert"
            type="success"
            color="primary"
            style="position: absolute; right: 0px; bottom: 0px"
        >
            <v-row no-gutters>
                <v-col cols="11">
                    <p class="my-0">{{ alertMessage }}</p>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        small
                        icon
                        class="mt-n1"
                        @click="activateAlert = false"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script>
import ItemReserve from '@/components/StockRoom/ItemReserve.vue'
import NewItem from '@/components/StockRoom/NewItem.vue'
import _ from 'lodash'
import API from '@/services/api'
import { mapMutations } from 'vuex'
import * as XLSX from 'xlsx/xlsx.mjs'
import { storage } from '@/services/firebase'
import { jsPDF } from 'jspdf'
import '@/assets/fonts/arial-normal.js'
import '@/assets/fonts/arialbd-normal.js'
import qrious from 'qrious'

export default {
    name: 'Stock',
    props: {
        type: String,
        projectId: {
            type: String,
            default: () => undefined,
        },
    },
    components: {
        ItemReserve,
        NewItem,
    },
    data: () => ({
        codesFile: undefined,
        valid: false,
        dialog: false,
        codesUCList: [],
        projects: [],
        logoCode: null,
        loading: false,
        openNewItem: false,
        openPurchase: false,
        openReserve: false,
        selectedItem: {},
        headers: [
            {
                text: 'CODE',
                value: 'code',
                align: 'center',
                sortable: false,
            },
            {
                text: 'DESCRIPTION',
                value: 'description',
                align: 'center',
                sortable: false,
            },
            {
                text: 'AVAILABLE',
                value: 'available',
                align: 'center',
                sortable: false,
            },
            {
                text: 'RESERVED',
                value: 'reserved',
                align: 'center',
                sortable: false,
            },
            {
                text: 'PRICE (UNIT)',
                value: 'price',
                align: 'center',
                sortable: false,
            },
            {
                text: 'TOTAL PRICE',
                value: 'total',
                align: 'center',
                sortable: false,
            },
            {
                text: 'ACTIONS',
                value: 'actions',
                align: 'center',
                sortable: false,
            },
        ],
        insights: [
            {
                color: '#3498DB',
                icon: 'mdi-currency-usd',
                iconColor: '#1A5276',
                title: 'TOTAL ESM STOCK',
            },
        ],
        formData: {
            name: '',
            cod_esm: '',
            cod_tgls: '',
            aa: '',
            tple: '',
            uc: '',
            acb: '',
            clr: '',
            oc: '',
            proy: '',
        },
        items: [],
        search: '',
        searchText: '',
        uploadStock: null,
        activateAlert: false,
        file: undefined,
        openUploadFile: false,
        rules: {
            required: v => !!v || 'The file is required',
        },
        tempItems: [],
        fileHeaders: {
            Material: 'material',
            'Texto breve de material': 'short_material_text',
            'Alm.': 'storage',
            'Nombre de proyecto': 'project_name',
            Orden: 'order',
            'Libre utilización': 'free_use',
            'Valor libre util.': 'usable_free_value',
        },
        companyId: JSON.parse(localStorage.getItem('company')),
        loading2: false,
        codes: [],
        settings: null,
        selectedItems: [],
        reset: false,
        downloadFiles: false,
        storeMNP: 1158,
        sapCodeMNP: 'MNP-001',
        mockupProjectName: 'Mockup No Proyetos',
        storeNC: 1157,
        storeLouvers: 1159,
        sapCodeLouvers: 'LVR-001',
        LouversProjectName: 'Stock Louvers',
    }),
    async created() {
        const {
            data: { user },
        } = await API.getMyInfo()
        this.uploadStock = user.permissions.find(
            permission => permission == 'uploadStock'
        )
    },
    async mounted() {
        try {
            this.loading = true
            this.projects = await API.getLiteProjectsByUser()
            const {
                data: { settings },
            } = await API.getSettings()
            this.settings = settings
            const indexUC = this.settings.findIndex(
                q => q.name == 'FinishAndUCCode'
            )
            const indexLogo = this.settings.findIndex(q => q.name == 'Company')
            this.logoCode = this.settings[indexLogo].generalInfo.logoBase64
            this.codesUCList = this.settings[indexUC].finishes
            await this.getStock()
            await this.getStockCodes()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    watch: {
        async downloadFiles() {
            if (this.downloadFiles) {
                this.loading = true
                await this.getStock()
                await this.getStockCodes()
                this.downloadFiles = false
                this.loading = false
            }
        },
    },
    computed: {
        filterItems() {
            const conditions = []
            if (this.selectedItems.length > 0) {
                return this.selectedItems
            } else {
                if (this.searchText) {
                    conditions.push(this.filterData)
                }
                if (conditions.length > 0) {
                    return this.items.filter(item => {
                        return conditions.every(condition => {
                            return condition(item)
                        })
                    })
                }
                return this.items
            }
        },
        validateForm() {
            const { proy } = this.formData
            return proy
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        resetSearch() {
            this.selectedItems = []
            this.search = ''
            this.reset = false
        },
        calculateTotal() {
            let total = 0
            total = this.filterItems.reduce(
                (accumulator, item) =>
                    accumulator + Number(item.total.replace(/\./g, '')),
                0
            )
            return this.numberFormat(total)
        },
        async searchItem() {
            try {
                this.selectedItems = []
                this.loading = true
                let selectedItem = this.items.find(
                    item => item.code == this.search.material
                )
                if (selectedItem) {
                    this.selectedItems = [selectedItem]
                    if (Number(selectedItem.available) > 0) {
                        this.openDialogReserve(selectedItem)
                    }
                } else if (this.search == '') {
                    this.selectedItems = [
                        {
                            code: this.search.material,
                            description: this.search.short_material_text,
                            available: 0,
                            reserved: 0,
                            price: 0,
                            total: 0,
                            items: [this.search],
                        },
                    ]
                }
                this.reset = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async getStockCodes() {
            try {
                this.codes = await API.getStockCodes()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async getStock() {
            try {
                this.items = await API.getStock()
                this.items.forEach(item => {
                    item.available = this.numberFormat(item.available)
                    item.reserved = this.numberFormat(item.reserved)
                    item.price = this.numberFormat(item.price)
                    item.total = this.numberFormat(item.total)
                })
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        numberFormat(amount) {
            return new Intl.NumberFormat('de-DE').format(Math.ceil(amount))
        },
        async uploadFile() {
            try {
                this.loading2 = true
                this.tempItems = []
                const storageRef = storage().ref(`${this.companyId}/stock`)
                if (this.file) {
                    let stock = await this.readFile(this.file, true)
                    if (stock) {
                        stock = this.processFiles(stock[0])
                        await storageRef
                            .child('stock.json')
                            .putString(JSON.stringify(stock))
                    }
                }
                if (this.codesFile) {
                    let codes = await this.readFile(this.codesFile)
                    codes = this.processFiles(codes[0])
                    await storageRef
                        .child('codes.json')
                        .putString(JSON.stringify(codes))
                }
                this.loading2 = false
                this.closeDialogToUploadFile()
                this.downloadFiles = true
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        openDialogToUploadFile() {
            this.openUploadFile = true
        },
        closeDialogToUploadFile() {
            this.openUploadFile = false
            this.file = undefined
            this.codesFile = undefined
        },
        save() {
            this.closeDialogNewItem()
            this.activateAlert = true
            this.alertMessage = 'New item was successfully requested'
        },
        reserve() {
            this.closeDialogReserve()
            this.activateAlert = true
            this.alertMessage = 'Stock was successfully reserved'
        },
        openDialogNewItem() {
            this.openNewItem = true
        },
        closeDialogNewItem() {
            this.openNewItem = false
        },
        openDialogPurchase(item) {
            this.openPurchase = true
            this.selectedItem = _.cloneDeep(item)
        },
        closeDialogPurchase() {
            this.openPurchase = false
            this.selectedItem = {}
        },
        openDialogReserve(item) {
            this.loading = true
            this.openReserve = true
            this.selectedItem = _.cloneDeep(item)
        },
        async closeDialogReserve() {
            this.loading = false
            this.openReserve = false
            this.selectedItem = {}
            await this.getStock()
        },
        async readFile(file, checkRequired = false) {
            try {
                this.tempItems = []
                this.loading = true
                const data = await file.arrayBuffer()
                /* data is an ArrayBuffer */
                const workbook = XLSX.read(data)
                var first_sheet_name = workbook.SheetNames[0]
                /* Get worksheet */
                var worksheet = workbook.Sheets[first_sheet_name]
                const range = XLSX.utils.decode_range(worksheet['!ref'])
                for (let col = range.s.c; col <= range.e.c; col++) {
                    const cellAddress = XLSX.utils.encode_cell({
                        r: range.s.r,
                        c: col,
                    })
                    const cell = worksheet[cellAddress]

                    if (cell) {
                        if (cell.v)
                            worksheet[cellAddress].v = cell.v.toString().trim()
                        if (cell.h)
                            worksheet[cellAddress].h = cell.h.toString().trim()
                        if (cell.w)
                            worksheet[cellAddress].w = cell.w.toString().trim()
                    }
                }

                if (checkRequired) {
                    // get headers
                    const headers = []

                    for (let col = range.s.c; col <= range.e.c; col++) {
                        const cellAddress = XLSX.utils.encode_cell({
                            r: range.s.r,
                            c: col,
                        })
                        const cell = worksheet[cellAddress]
                        headers.push(cell ? cell.v.trim() : undefined)
                    }

                    // requiredHeaders
                    const requiredColumns = [
                        'Material',
                        'Texto breve de material',
                        'Alm.',
                        'Orden',
                        'Libre utilización',
                        'Valor libre util.',
                    ]
                    const missingColumns = requiredColumns.filter(
                        col => !headers.includes(col)
                    )

                    if (missingColumns.length > 0) {
                        throw new Error(
                            `The following columns are missing from the file: ${missingColumns.join(
                                ', '
                            )}`
                        )
                    }
                }

                this.tempItems.push(
                    XLSX.utils.sheet_to_json(worksheet, {
                        raw: true,
                    })
                )
                return this.tempItems
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        processFiles(items) {
            let response = []
            items.forEach(item => {
                if (item['Material']) {
                    if (item['Alm.'] == this.storeMNP) {
                        item['Nombre de proyecto'] = this.mockupProjectName
                        item['Orden'] = this.sapCodeMNP
                    } else if (item['Alm.'] == this.storeLouvers) {
                        item['Nombre de proyecto'] = this.LouversProjectName
                        item['Orden'] = this.sapCodeLouvers
                    }
                    if (item['Alm.'] != this.storeNC) {
                        const newObject = {}
                        Object.keys(this.fileHeaders).map(key => {
                            newObject[this.fileHeaders[key]] =
                                item[key] || item[key.trim()] || ''
                        })
                        response.push(newObject)
                    }
                }
            })
            return response
        },
        filterData(item) {
            return (
                item.code
                    .toString()
                    .toLowerCase()
                    .includes(this.searchText.toLowerCase()) ||
                item.description
                    .toLowerCase()
                    .includes(this.searchText.toLowerCase())
            )
        },
        async handleButtonClick(item) {
            this.formData.name = item.short_material_text
            this.formData.cod_esm = item.material
            this.formData.cod_tgls = ''
            this.formData.aa = ''
            this.formData.tple = ''
            this.formData.uc = ''
            this.formData.acb = ''
            this.formData.clr = ''
            this.formData.oc = ''
            this.formData.proy = ''
            this.formData.logo = this.logoCode
            this.dialog = true
        },
        saveForm() {
            this.generatePDF(this.formData)
            this.dialog = false
        },
        generatePDF(data) {
            const dataForQR =
                'name=' +
                data.name +
                'Code.ESM=' +
                data.cod_esm +
                'Code.TGLS=' +
                data.cod_tgls +
                'AA=' +
                data.aa +
                'TPLE=' +
                data.tple +
                'UC=' +
                data.uc +
                'ACB=' +
                data.acb +
                'CLR=' +
                data.clr +
                'OC=' +
                data.oc +
                'PROY=' +
                data.proy
            var qr = new qrious({
                value: dataForQR,
                size: 500,
            })

            const doc = new jsPDF('landscape', 'mm', [100, 75]) //set dimensions
            doc.setFont('arialbd', 'normal')
            doc.setFontSize(11)
            doc.text(data.name, 50, 10, null, null, 'center')
            doc.text('COD. ESM', 4, 20)
            doc.setFont('arial', 'normal')
            doc.setFontSize(10)
            doc.text(String(data.cod_esm), 25, 20)
            doc.setFont('arialbd', 'normal')
            doc.setFontSize(11)
            doc.text('COD. TGLS', 51, 20)
            doc.setFont('arial', 'normal')
            doc.setFontSize(10)
            doc.text(data.cod_tgls, 74, 20)
            doc.setFont('arialbd', 'normal')
            doc.setFontSize(11)
            doc.text('AA:', 4, 30)
            doc.setFont('arial', 'normal')
            doc.setFontSize(10)
            doc.text(data.aa, 13, 30)
            doc.setFont('arialbd', 'normal')
            doc.setFontSize(11)
            doc.text('TPLE:', 27, 30)
            doc.setFont('arial', 'normal')
            doc.setFontSize(10)
            doc.text(data.tple, 41, 30)
            doc.setFont('arialbd', 'normal')
            doc.setFontSize(11)
            doc.text('UC:', 51, 30)
            doc.setFont('arial', 'normal')
            doc.setFontSize(10)
            doc.text(data.uc, 61, 30)
            doc.setFont('arialbd', 'normal')
            doc.setFontSize(11)
            doc.text('ACB:', 4, 40)
            doc.setFont('arial', 'normal')
            doc.setFontSize(10)
            doc.text(data.acb, 17, 40)
            doc.setFont('arialbd', 'normal')
            doc.setFontSize(11)
            doc.text('CLR:', 51, 40)
            doc.setFont('arial', 'normal')
            doc.setFontSize(10)
            doc.text(data.clr, 61, 40)
            doc.setFont('arialbd', 'normal')
            doc.setFontSize(11)
            doc.text('OC:', 4, 50)
            doc.setFont('arial', 'normal')
            doc.setFontSize(10)
            doc.text(data.oc, 14, 50)
            doc.setFont('arialbd', 'normal')
            doc.setFontSize(11)
            doc.text('PROY:', 4, 60)
            doc.setFont('arial', 'normal')
            doc.setFontSize(10)
            doc.text(data.proy.split(' - ')[0], 19, 60)
            const imgProps2 = doc.getImageProperties(data.logo)
            doc.addImage(qr.toDataURL('image/jpeg'), 'JPEG', 50, 45, 20, 20)
            const pdfWidth = 20
            const pdfHeight = (imgProps2.height * pdfWidth) / imgProps2.width
            doc.addImage(
                data.logo,
                'JPEG',
                75,
                45 + (pdfWidth - pdfHeight) / 2,
                pdfWidth,
                pdfHeight
            )

            doc.save(`${data.cod_esm}_for_${data.proy.replace(/ /g, '')}.pdf`)
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead {
    background: #eeeeee;
}
</style>
